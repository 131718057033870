html,
body {
  height: 100%;
  width: 100%;
  background-color: #F3F6FB;
}
body {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
body.fontLoaded {
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
body ::-webkit-scrollbar {
  width: 3px;
}
#app {
  background-color: #F3F6FB;
  min-height: 100%;
  min-width: 100%;
  overflow-x: 'auto'
}
body, 
.ui.header,
.ui.menu .item,
.ui.secondary.menu .dropdown.item:hover, 
.ui.secondary.menu .link.item:hover, 
.ui.secondary.menu a.item:hover,
.ui.vertical.right.tabular.menu .active.item,
.ui.tabular.menu .item,
.ui.link.menu .item:hover, 
.ui.menu .dropdown.item:hover, 
.ui.menu .link.item:hover, 
.ui.menu a.item:hover,
.ui.link.list.list .item a:not(.ui):hover, 
.ui.link.list.list a.item:hover
{
  color: #3d5986;
}
.ui.text.menu .item,
.ui.link.list .item, 
.ui.link.list .item a:not(.ui), 
.ui.link.list a.item
{
  color: rgba(61, 89, 134, 0.6)
}
#test {
  padding: 0px !Important
}
.ui.inverted.dimmer {
  /* background-color: #fff; */
  /* transition: unset; */
}
.ui.card {
  overflow: hidden;
}
.ui.dropdown .menu>.divider {
  margin: 0;
}
.ui.card .dimmer, .ui.cards>.card .dimmer {
  z-index: 9;
}
.ui.styled.accordion>.title:first-child {
  border-top: none;
}
@media only screen and (max-width: 767px) {
  .ui.stackable.menu .item {
      width: auto !important;
  }

  .ui.selection.dropdown .menu {
    max-height: 20rem;
  }
}

/* @font-face {
  font-family: 'Recoleta Bold';
  src: url(assets/fonts/Recoleta-Bold.otf) format("opentype");
} */

@font-face {
  font-family: 'Recoleta Bold';
  src:  url(assets/fonts/Recoleta-Bold.otf) format('opentype'),
        url(assets/fonts/Recoleta-Bold.woff2) format('woff2'),
        url(assets/fonts/Recoleta-Bold.woff) format('woff'),
        url(assets/fonts/Recoleta-Bold.ttf) format('truetype');
}
